<script>

import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    PageHeader,
  },
  data() {
    return {

      user: {},
      team_member: null,
      searchUsers: null,
      SearchMode: false,
      searchText: "",
      surveys: [],
      tot_pages: []
    };
  },
  computed: {
    showSurveys() {
      return this.surveys;
    },
  },
  methods: {
    navigate(survey) {

      this.$router.push({ name: 'team_member_surveys_details', params: { id: survey?.id } })
    },
    search() {
      this.SearchMode = true;
      if (this.searchText == "") {
        this.surveys = this.team_member?.marketing_survey_team_members;
      } else {
        this.surveys =
          this.team_member?.marketing_survey_team_members?.filter((i) =>
            i?.marketing_survey?.name
              ?.toLowerCase()
              ?.includes(this.searchText?.toLowerCase())
          );
        console.log({ surveys: this.surveys });
      }
    },
    cancelSearchMode() {
      this.searchUsers = "";
      this.SearchMode = false;
      this.get(this.page);
    },
    getTeamMember() {
      this.http
        .get(
          `marketing-team-members/${this.user?.id}`, null, null, true
        )
        .then((res) => {
          this.team_member = res.data;
          this.surveys = res?.data?.marketing_survey_team_members;
          console.log({ surveys: this.surveys });
        });
    },
  },
  created() {
    this.user = JSON.parse(localStorage.user)
    console.log('user : ', this.user);
    this.getTeamMember();
  },
};
</script>

<template>
  <PageHeader :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
    :title="$t('menu.menuitems.surveys.subItems.team_member_surveys')" />
  <div class="row mb-4">
    <div class="col-4">
      <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px">
        <div class="align-items-center d-flex position-relative mx-3">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchText"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <!-- <button
              @click="cancelSearchMode()"
              v-if="SearchMode"
              class="btn-close text-light"
              style="position: absolute; top: 12px; left: 250px"
            ></button> -->
        </div>
      </div>
    </div>

    <div class="col-8"></div>
  </div>
  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">{{ $t("marketing.survey_list.table.id") }}</th>
          <th scope="col">
            {{ $t("marketing.survey_list.table.name") }}
          </th>
          <th scope="col">{{ $t("popups.rate") }}</th>
          <th scope="col">{{ $t("users.userstable.created") }}</th>
          <th scope="col">{{ $t("users.userstable.updated") }}</th>
          <th scope="col">
            {{ $t("marketing.survey_list.table.details") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center" v-for="(user, index) in showSurveys" :key="user">
          <td>{{ index + 1 }}</td>
          <td>{{ user?.marketing_survey?.name }}</td>
          <td>{{ user?.rate ? user?.rate : "--" }}</td>
          <td>{{ user?.created?.split("T")[0] }}</td>
          <td>{{ user?.updated?.split("T")[0] }}</td>
          <td>
            <button @click="navigate(user)" class="btn btn-primary btn-sm me-2">
              <i class="bx bx-detail"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end  table -->

  <!--   Apps  Pagination     -->

  <!--end-->
</template>
